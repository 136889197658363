import {useEffect, useState} from "react";
import MessagesModal from "../MessagesModal";

const GenerateAdditionalBlock = ({ scriptSelectFilter, dataRange, assistantId, tab }) => {
  const [show, setShow] = useState(false);
  const [filterTab, setFilterTab] = useState(tab);
  const [filters, setFilters] = useState({});

  const toggleShow = () => setShow(prevShow => !prevShow);

  useEffect(() => {
    const [startDate, endDate] = dataRange.current.value.split(" - ");

    setFilters({
      startDate: startDate,
      endDate: endDate,
      scriptId: scriptSelectFilter,
      tab: filterTab,
    });
  }, [scriptSelectFilter, dataRange, filterTab]);

  return (
    <>
      <button className="btn btn-link p-0" onClick={toggleShow}>
        {I18n.t("views.leadster_ai.page.analytics.messages_modal.text_button")}
      </button>

      <MessagesModal
        show={show}
        handleClose={toggleShow}
        title={I18n.t("views.leadster_ai.page.analytics.messages_modal.title")}
        filters={filters}
        dataRange={dataRange.current.value}
        assistantId={assistantId}
        setFilterTab={setFilterTab}
      />
    </>
  );
};

export default GenerateAdditionalBlock;
