import { useState } from "react";

import { useForm } from "react-hook-form";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import parse from "html-react-parser";

const ConfigurationButton = ({ rejectLead, onSaveSettings, label, icon, color }) => {
  const { register, setValue, handleSubmit } = useForm();

  const [modalShow, setModalShow] = useState(false);

  const handleOpenModal = () => setModalShow(true);

  const handleCloseModal = () => {
    setModalShow(false);
    setValue("rejectLead", rejectLead);
  };

  const onSubmit = (data) => {
    onSaveSettings(data);
    setModalShow(false);
  };

  return (
    <>
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip>{I18n.t("views.manage_flows.script_editor.click_here_to_edit_settings")}</Tooltip>}
      >
        <Button className={`${color}`} variant="success" onClick={handleOpenModal}>
          <i className={`ti ${icon} me-2`} />
          {I18n.t("views.manage_flows.script_editor.condition")}{label ? ` [${label}]` : ""}
          {rejectLead ? <i className="fa fa-times ms-2 text-warning" /> : null}
          <i className="ti ti-dots-vertical ms-1" />
        </Button>
      </OverlayTrigger>

      <Modal show={modalShow} className="modal-blur" centered onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {I18n.t("views.manage_flows.script_editor.conditional_settings")}
          </Modal.Title>
        </Modal.Header>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <label className="form-check form-switch">
              <input
                type="checkbox"
                className="form-check-input"
                defaultChecked={rejectLead}
                {...register("rejectLead")}
              />

              <span className="form-check-label fw-medium">
                {I18n.t("views.manage_flows.script_editor.discard_disqualified_lead")}
              </span>
            </label>

            <div className="form-text text-body">
              {parse(I18n.t("views.manage_flows.script_editor.discard_lead_description_html"))}
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="" className="me-auto" onClick={handleCloseModal}>
              {I18n.t("shared.actions.cancel")}
            </Button>

            <Button variant="primary" type="submit">
              {I18n.t("shared.actions.save")}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default ConfigurationButton;
