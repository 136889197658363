import { Row, Col, Form } from "react-bootstrap";

import ColSkeleton from "../ColSkeleton";
import CardInformation from "../CardInformation";
import AiAssistantAlert from "../FrequentlyAskedQuestions/components/AiAssistantAlert";
import { getAiSupportMessages } from "../FrequentlyAskedQuestions/utils";

import { can } from "@/lib/user-permission";

import ButtonExport from "@/components/leadster_ia/shared/ButtonExport";
import ModalSupportExport from "@/components/leadster_ia/cards/Analytics/export/ModalSupportExport";
import supportParamsExport from "@/components/leadster_ia/cards/Analytics/store/supportParamsExport";
import GenerateAdditionalBlock from "../GenerateAdditionalBlock";

const AiSupport = ({
  handleScriptFilterChange,
  scriptFilter,
  dataRange,
  isLoading,
  currentData,
  assistantId,
  scriptSelectFilter
}) => {
  const hasPermission = can({ action: "manage", subject: "leadster_ai_plugin" });

  const {
      message1,
      message2,
      linkTo,
    } = getAiSupportMessages(hasPermission);

  return (
    <div>
      {message1 && <AiAssistantAlert message1={message1} message2={message2} linkTo={linkTo} />}

      <Row className="mb-3">
        <Col>
          <Form.Select onChange={handleScriptFilterChange} defaultValue="all">
            <option value="all">
              {I18n.t("views.leadster_ai.page.analytics.dashboard.all_flows")}
            </option>

            {scriptFilter?.map(({ id, name }) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
          </Form.Select>
        </Col>

        <Col>
          <Form.Control ref={dataRange} readOnly />
        </Col>

        <Col md={2}>
          <ButtonExport Modal={ModalSupportExport} disable={supportParamsExport.getState().disable_button} />
        </Col>
      </Row>

      <div className="text-muted small mb-3">
        {I18n.t("views.leadster_ai.page.analytics.dashboard.ai_support_subtitle")}
      </div>

      <Row className="mb-3 g-3 row-deck row-cards">
        {isLoading ? (
          <ColSkeleton itensNum={6} />
        ) : (
          <>
            <Col sm={4}>
              <CardInformation {...currentData?.total_visitors} />
            </Col>
            <Col sm={4}>
              <CardInformation {...currentData?.average_duration} />
            </Col>
            <Col sm={4}>
              <CardInformation {...currentData?.average_questions} />
            </Col>
            <Col sm={4}>
              <CardInformation {...currentData?.total_questions} />
            </Col>
            <Col sm={4}>
              <CardInformation {...currentData?.answered_questions} />
            </Col>
            <Col sm={4}>
              <CardInformation {...currentData?.unanswered_questions} />
            </Col>
            <Col sm={4}>
              <CardInformation {...currentData?.satisfactory_response}>
                <GenerateAdditionalBlock
                  scriptSelectFilter={scriptSelectFilter}
                  dataRange={dataRange}
                  assistantId={assistantId}
                  tab={"satisfactory"}
                />
              </CardInformation>
            </Col>
            <Col sm={4}>
              <CardInformation {...currentData?.unsatisfactory_response}>
                <GenerateAdditionalBlock
                  scriptSelectFilter={scriptSelectFilter}
                  dataRange={dataRange}
                  assistantId={assistantId}
                  tab={"unsatisfactory"}
                />
              </CardInformation>
            </Col>
            <Col sm={4}>
              <CardInformation {...currentData?.unassessed_response} />
            </Col>
          </>
        )}
      </Row>
    </div>
  );
};

export default AiSupport;
