import { useState, useEffect, useRef } from "react";

import { CancelToken, isCancel } from "axios";
import swal from "sweetalert";

import { cleanObjectEmptyValues } from "@/utils/helpers";

import axios from "@/lib/axios";

const initialPaginationData = {
  current_page: 1,
  limit_value: 10,
  next_page: 2,
  prev_page: null,
  total_pages: 0,
  total_count: 0,
};

const usePagination = (
  endpoint,
  {
    defaultPerPage = 10,
    dataIndex = "data",
    defaultDataValue = null,
    errorFeedbackLib = "swal",
    filters = {},
    dependencies = [],
    onError = (err) => {},
    onSuccess = (data, paginationData) => {},
  } = {}
) => {
  const cancelToken = useRef();

  const [data, setData] = useState(defaultDataValue);
  const [paginationData, setPaginationData] = useState(initialPaginationData);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(defaultPerPage);

  useEffect(() => {
    doFetch();

    return () => cancelToken.current.cancel();
  }, [currentPage, perPage, ...dependencies]);

  const errorFeedback = () => {
    switch (errorFeedbackLib) {
      case "swal":
        swal(
          I18n.t("shared.messages.request_feedback_alert.error.title"),
          I18n.t("shared.messages.request_feedback_alert.error.message"),
          "error"
        );
        break;
      case "toastr":
        toastr.error(I18n.t("shared.messages.request_feedback_alert.error.message"));
        break;
      default:
        break;
    }
  };

  const nextPage = () => {
    setCurrentPage((currentPage) => currentPage + 1);
  };

  const prevPage = () => {
    if (currentPage <= 1) return;

    setCurrentPage((currentPage) => currentPage - 1);
  };

  const doFetch = () => {
    setIsLoading(true);
    setError(null);

    const parsedFilters = cleanObjectEmptyValues(filters);
    const queryParams = {
      page: currentPage,
      per_page: perPage,
      ...parsedFilters,
    };

    cancelToken.current = CancelToken.source();

    axios
      .get(endpoint, {
        cancelToken: cancelToken.current.token,
        params: queryParams,
      })
      .then(({ data }) => {
        const resultData = _.get(data, dataIndex, defaultDataValue);
        const paginationData = _.get(data, "meta.pagination", initialPaginationData);

        setData(resultData);
        setPaginationData(paginationData);

        onSuccess(resultData, paginationData);
      })
      .catch((err) => {
        if (isCancel(err)) return;

        setError(err);
        errorFeedback();
        onError(err);
      })
      .finally(() => setIsLoading(false));
  };

  return {
    data,
    paginationData,
    error,
    currentPage,
    isLoading,
    setCurrentPage,
    setPerPage,
    nextPage,
    prevPage,
    doFetch,
  };
};

export default usePagination;
