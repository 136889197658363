import { useEffect, useState } from "react";

import { Modal, Tab } from "react-bootstrap";

import MessageItem from "./MessageItem";
import usePagination from "@/hooks/usePagination";
import useInfiniteScroll from "@/hooks/useInfiniteScroll";

import * as S from "./styles.js";

const MessagesModal = ({ show, handleClose, title, filters, assistantId, setFilterTab }) => {
  if (!show) return null;

  const [activeKey, setActiveKey] = useState(filters.tab);
  const [messages, setMessages] = useState([]);

   const {
    data,
    paginationData,
    isLoading,
    setCurrentPage,
  } = usePagination(`/ai/assistants/${assistantId}/qualified_messages`, {
    defaultDataValue: [],
    defaultPerPage: 5,
    dataIndex: "messages",
    errorFeedbackLib: false,
    filters: {
      start_date: filters.startDate,
      end_date: filters.endDate,
      script_id: filters.scriptId,
      tab: filters.tab,
    },
  });

  const { targetRef: targetRefSatisfied } = useInfiniteScroll({
    callback: () => setCurrentPage(paginationData.next_page),
    threshold: 0.3,
    enabled: activeKey === "satisfactory" && !!paginationData.next_page,
  });

  const { targetRef: targetRefUnsatisfied } = useInfiniteScroll({
    callback: () => setCurrentPage(paginationData.next_page),
    threshold: 0.3,
    enabled: activeKey === "unsatisfactory" && !!paginationData.next_page,
  });

  useEffect(() => {
    setMessages(data);
  }, [show]);

  useEffect(() => {
    if (data.length > 0) {
      setMessages((prev) => [...prev, ...data]);
    }
  }, [data]);

  useEffect(() => {
    setCurrentPage(1);
    setMessages([]);
  }, [filters]);



  const handleSelect = (key) => {
    setActiveKey(key);
    setFilterTab(key);
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="modal-lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold text-start w-100">
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0 bg-white rounded-bottom">
        <S.Tabs
          activeKey={activeKey}
          id="custom-tabs"
          onSelect={handleSelect}
          className="mb-0 border-bottom pb-2 pt-3 ps-3"
        >
          <Tab
            eventKey="satisfactory"
            title={
              <span>
                <i className={`icon ti ti-thumb-up text-primary me-2`}/>
                {I18n.t("views.leadster_ai.page.analytics.messages_modal.tab_satisfied")}
              </span>
            }
          >
            <div>
              <div className="py-2 mb-3" style={{ maxHeight: "150px", overflowY: "auto" }}>
                {messages.map((message, index) => {
                  return (
                    <MessageItem key={index} message={message} />
                  )
                })}
                {isLoading &&
                  <div className="d-flex justify-content-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                }
                {!isLoading > 0 &&
                  <div ref={targetRefSatisfied} style={{ height: "2px" }} />
                }
              </div>
            </div>
          </Tab>
          <Tab
            eventKey="unsatisfactory"
            title={
              <span>
                <i className={`icon ti ti-thumb-down text-primary me-2`}/>
                {I18n.t("views.leadster_ai.page.analytics.messages_modal.tab_dissatisfied")}
              </span>
            }
          >
            <div>
              <div className="py-2 mb-3" style={{ maxHeight: "150px", overflowY: "auto" }}>
                {messages.map((message, index) => {
                  return (
                    <MessageItem key={index} message={message} />
                  )
                })}
                {isLoading &&
                <div className="d-flex justify-content-center">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
                }
                {!isLoading > 0 &&
                <div ref={targetRefUnsatisfied} style={{ height: "2px" }} />
                }
              </div>
            </div>
          </Tab>
        </S.Tabs>
      </Modal.Body>
    </Modal>
  );
};

export default MessagesModal;
